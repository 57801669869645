    <template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="3">
        <search-item
          :searchString="searchString"
          :no-results="true"
          @setSearchString="setSearchString"
          @get-results="getItemResults"
          @clear-input="clearItem"
        ></search-item>
      </v-col>
      <v-col cols="9" class="d-flex align-self-center justify-end">
        <v-btn-toggle color="primary">
          <v-btn color="primary" @click="openItemModal(defaultItem)"
            >Nieuw artikel</v-btn
          >
          <v-btn color="primary" to="/itemsSalesHistory">Artikelhistorie</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col>
        <v-btn-toggle>
          <v-btn color="primary" @click="selectCategory(false)">
            Alles
          </v-btn>
          <v-btn
            color="primary"
            v-for="category of $store.state.settings.categories"
            :key="category"
            @click="selectCategory(category)"
          >
            {{ category }}
          </v-btn>
          <v-btn color="primary" @click="selectArchived">Archief</v-btn>
        </v-btn-toggle></v-col
      >
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :options.sync="options"
      :server-items-length="itemsLength"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 25, 50, -1]
      }"
      class="elevation-1"
      item-key="_id"
    >
      <template v-slot:[`item.sort`]="{ item }"
        ><input @change="updateItem(item)" size="3" v-model="item.sort"
      /></template>
      <template v-slot:[`item.name`]="{ item }"
        ><a @click="openItemModal(item)"
          ><b>{{ item.name }}</b></a
        ></template
      >
      <template v-slot:[`item.price`]="{ item }">{{
        toCurrency(item.price)
      }}</template>
      <template v-slot:[`item.VAT`]="{ item }">{{ item.VAT }}%</template>
      <template v-slot:[`item.unitAmount`]="{ item }"
        >{{ item.unitAmount }}kg</template
      >
      <template v-slot:[`item.stock`]="{ item }">{{ toDouble(item.stock) }}kg</template>
      <template v-slot:[`item.updated`]="{ item }">{{ toDateString(item.updated) }}</template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon large @click="openPrintLabelModal(item)">mdi-label</v-icon>
      </template>
      <template v-slot:[`item.supplierNumber`]="{ item }">
        <v-select
                label="Leverancier"
                v-model="item.supplierNumber"
                item-text="name"
                item-value="supplierNumber"
                :items="suppliers"
                @change="generateLotNumber(item)"
                ></v-select>
      </template>
    </v-data-table>
    <item-modal
      v-if="itemModal"
      @insert-item="insertItem"
      @update-item="updateItem"
      @delete-item="deleteItem"
      @close-item-modal="closeItemModal"
      :itemModal="itemModal"
      :item="item"
    ></item-modal>
    <print-label-modal
      v-if="printLabelModal"
      @update-item-supplier-and-lot-number="updateItemSupplierAndLotNumber"
      @close-print-label-modal="closePrintLabelModal"
      :printLabelModal="printLabelModal"
      :item="item"
    ></print-label-modal>
  </div>
</template>
    <script>
import searchItem from "../components/searchItem.vue"
import itemModal from "../components/itemModal.vue"
import {
  errorHandler,
  fetchGET,
  fetchPOST,
  successHandler,
  toCurrency,
  toDateString,
  toDouble
} from "../js/functions"
import printLabelModal from "../components/printLabelModal.vue"
export default {
  name: "items",
  data() {
    return {
      item: {},
      items: [],
      itemsLength: 0,
      defaultItem: {
        name: "",
        price: 0,
        unit: "x",
        unitAmount: "",
        category: "",
        amount: 0,
        copies: 0,
        sort: 999,
        VAT: 9,
        packaging: "",
        itemNumber: 0,
        totalVAT: 0,
        grossSubtotal: 0,
        netSubtotal: 0,
        cost: 0,
        totalCost: 0,
        profit: 0,
        totalProfit: 0,
        status: 0,
        assigned: false,
        packageAmount: 1,
        packages: 0,
        crates: 0,
        lotNumber: "",
        description: "",
        commodityCode: "",
        stock: 0,
      },
      searchString: "",
      headers: [
        { text: "Artikelnr", value: "itemNumber" },
        { text: "Naam", value: "name" },
        { text: "Verkoopprijs", value: "price" },
        { text: "BTW", value: "VAT" },
        { text: "Eenheid", value: "unit" },
        { text: "Eenheidsgewicht", value: "unitAmount" },
        { text: "Verpakkingsinhoud", value: "packageAmount" },
        { text: "Voorraad", value: "stock" },
        { text: "Categorie", value: "category" },
        { text: "Gewijzigd", value: "updated" },
        { text: "Sort", value: "sort" },
        { text: "Leverancier", value: "supplierNumber" },
        { text: "", value: "actions" }
      ],
      selectedCategory: "",
      showArchived: false,
      itemModal: false,
      printLabelModal: false,
      itemNumber: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["itemNumber"],
        sortDesc: [false]
      },
      client: {},
      fixedPrice: {},
      editFixedPrice: false,
      newFixedPrice: false,
      suppliers: []
    }
  },
  methods: {
  toDouble(n) {
      return toDouble(n)
    },
    toCurrency(n) {
      return toCurrency(n)
    },
    toDateString(d) {
      return toDateString(d)
    },
    async fetchItems() {
      let data = {
        selectedCategory: this.selectedCategory,
        showArchived: this.showArchived,
        options: this.options
      }
      let response = await fetchGET("fetchItems", data)
      this.items = response[0].results.length ? response[0].results : []
      this.itemsLength = response[0].length.length
        ? response[0].length[0].length
        : 0
    },
    selectCategory(cat) {
      this.searchString = ""
      this.selectedCategory = cat
      this.options.page = 1
      this.showArchived = false
      this.fetchItems()
    },
    selectArchived() {
      this.searchString = ""
      this.selectedCategory = false
      this.options.page = 1
      this.showArchived = true
      this.fetchItems()
    },
    getItemResults(items) {
      this.items = items
    },
    setSearchString(val) {
      this.searchString = val
    },
    clearItem() {
      this.options.page = 1
      this.itemNumber = 0
      this.searchString = ""
      this.fetchItems()
    },
    openItemModal(item) {
      this.item = item
      this.itemModal = true
    },
    closeItemModal() {
      this.item = {}
      this.itemModal = false
    },
    openPrintLabelModal(item) {
      this.item = item
      this.printLabelModal = true
    },
    closePrintLabelModal() {
      this.item = {}
      this.printLabelModal = false
    },
    async deleteItem(item) {
      try {
        let response = await fetchPOST("deleteItem", { _id: item._id })
        if (response.result.n == 0) throw "deleteItem"
        let index = this.items.findIndex(obj => obj._id === item._id)
        if (index > -1) this.items.splice(index, 1)
        successHandler("Artikel verwijderd")
        this.closeItemModal()
      } catch (e) {
        errorHandler(e, "Artikel niet verwijderd")
      }
    },
    async insertItem(item) {
      try {
        item.amount = 0
        item.inUnit = item.unit
        item.packages = 0
        item.sort = parseInt(item.sort)
        item.price = parseFloat(item.price)
        item.inPrice = item.price
        item.cost = parseFloat(item.cost)
        item.fixedPrices = []
        let response = await fetchPOST("insertItem", item)
        if (response.result.n == 0) throw "insertItem"
        this.items.push(response.ops[0])
        successHandler("Artikel opgeslagen")
        this.closeItemModal()
      } catch (e) {
        errorHandler(e, "Artikel niet opgeslagen")
      }
    },
    async updateItem(item) {
      try {
        item.sort = parseInt(item.sort)
        item.price = parseFloat(item.price)
        item.inPrice = item.price
        item.cost = parseFloat(item.cost)
        delete item.fixedPrices
        let response = await fetchPOST("updateItem", item)
        if (response.result.nModified == 0) throw "updateItem"
        Object.assign(this.item, item)
        successHandler("Artikel gewijzigd")
        this.closeItemModal()
      } catch (e) {
        errorHandler(e, "Artikel niet gewijzigd")
      }
    },
    async fetchSuppliers(){
      try {
        let res = await fetchGET("fetchSuppliers",{})
        this.suppliers = res.suppliers
      } catch (e) {
        errorHandler(e,"Fout bij ophalen")
      }
    },
    async generateLotNumber(item){
      try{
       let shortCodes = new Map()
    shortCodes.set("[*supplier*]",item.supplierNumber || "00")
    shortCodes.set("[*year*]",new Date().getFullYear())
    shortCodes.set("[*month*]",new Date().getMonth()+1)
    shortCodes.set("[*day*]",new Date().getDate())
    shortCodes.set("[*category*]",item.category)
    shortCodes.set("[*item*]",item.itemNumber)
    let shortCodesMatch = this.settings.traceability.lotNumberFormat .match(/\[\*[\s\S]+?\*\]/g)
    let lotNumberFormat = this.settings.traceability.lotNumberFormat
    for (let i = 0; i < shortCodesMatch.length; i++) {
      //add leading zero
      if(shortCodesMatch[i].includes("supplier")||shortCodesMatch[i].includes("month") || shortCodesMatch[i].includes("day") || shortCodesMatch[i].includes("item")){
        if(shortCodes.get(shortCodesMatch[i]) < 10){
          shortCodes.set(shortCodesMatch[i],"0"+shortCodes.get(shortCodesMatch[i]))
        }
      }
    lotNumberFormat = lotNumberFormat.replace(shortCodesMatch[i],shortCodes.get(shortCodesMatch[i]))
    }
    item.lotNumber = lotNumberFormat
    await this.updateItemSupplierAndLotNumber(item)
      }
      catch(err){
        errorHandler(err,"generateLotNumber")
      }
    },
    async updateItemSupplierAndLotNumber(obj){
      try {
        await fetchPOST("updateItemSupplierAndLotNumber",obj)
        let index = this.items.findIndex(item => item._id === obj._id)
      if (index > -1) {
        this.items[index].supplierNumber = obj.supplierNumber
        this.items[index].lotNumber = obj.lotNumber
      }
      }
      catch(err){
        errorHandler(err,"updateItemSupplierAndLotNumber")
      }
    }
  },
  watch: {
    options(newValue, oldValue) {
      let props = ["page", "itemsPerPage", "sortBy", "sortDesc"]
      for (let prop of props) {
        if (oldValue[prop] !== newValue[prop]) return this.fetchItems()
      }
    }
  },
  computed: {
    filteredItems() {
      if (this.selectedCategory) {
        return this.items.filter(item => item.category == this.selectedCategory)
      }
      return this.items
    },
    categories() {
      return this.$store.state.settings.categories
    },
    settings() {
      return this.$store.state.settings
    }
  },
  mounted() {
    this.fetchItems()
    this.fetchSuppliers()
  },
  components: { searchItem, itemModal, printLabelModal }
}
</script>
<style scoped="">
#item {
  width: 312px;
}
ul {
  list-style: none;
}
li {
  padding: 5px;
}
input {
  border: solid 1px lightgray;
  font-size: 1.4em;
  margin-right: 4px;
}
.statusImage {
  height: 20px;
  padding: 2px;
}
</style>